import { ReactElement } from 'react';
import CartContent from 'components/CartContent/CartContent';
import SideBar from 'components/SideBar/SideBar';

const CartSide = ({ cartOpen, toggleCart }: CartSideProps): ReactElement => (
  <SideBar open={cartOpen} toggleOpen={toggleCart} side="right">
    <CartContent side toggleCart={toggleCart} />
  </SideBar>
);

type CartSideProps = {
  cartOpen: boolean;
  toggleCart: () => void;
};

export default CartSide;
