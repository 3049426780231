import { ReactElement } from 'react';
import NextImage from 'next/image';

const Image = ({
  src,
  alt,
  circle,
  width,
  height,
  ...props
}: ImageProps): ReactElement => (
  <NextImage
    className={circle ? 'rounded-full' : 'rounded-md'}
    src={src}
    alt={alt}
    width={width}
    height={height}
    objectPosition="center"
    {...props}
  />
);

type ImageProps = {
  src: string | StaticImageData;
  alt: string;
  priority?: boolean;
  circle?: boolean;
  width?: number;
  height?: number;
  objectFit?: 'cover' | 'contain';
};

export default Image;
