import { ReactElement, useContext } from 'react';
import Link from 'next/link';
import { BiX } from 'react-icons/bi';
import { PrimaryButton, SecondaryButton } from 'styles/components/Button';
import Image from 'components/Image/Image';
import { Title } from 'styles/components/Title';
import Loading from 'components/Loading/Loading';
import { CheckoutContext } from 'context/CheckoutContext';

const CartContent = ({ side, toggleCart }: CartContentProps): ReactElement => {
  const { checkout, checkoutLoading, removeFromCart } = useContext(
    CheckoutContext,
  );

  const hasItems =
    checkout && checkout.lineItems && !!checkout.lineItems.length;

  return (
    <div className="flex flex-col flex-1 h-full">
      <Title>Shopping Cart</Title>

      {checkoutLoading && <Loading />}

      {hasItems && (
        <>
          <div className="flex-1">
            {checkout.lineItems.map(({ id, variant, quantity, title }) => {
              const handleRemoveFromCart = () => removeFromCart(id);

              return (
                <div className="flex justify-between mb-5 text-xl" key={title}>
                  <div className="w-48 mr-5 relative hover:cursor-pointer">
                    <Link href={`/product/${variant.product.handle}`} passHref>
                      <a
                        className="cursor-pointer"
                        href={`/product/${variant.product.handle}`}
                        onClick={toggleCart}
                      >
                        <Image
                          src={variant.image.src}
                          alt={variant.title}
                          height={100}
                          width={150}
                          objectFit="cover"
                        />
                      </a>
                    </Link>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex flex-col flex-1">
                      <Link
                        href={`/product/${variant.product.handle}`}
                        passHref
                      >
                        <a
                          className="cursor-pointer hover:text-main"
                          href={`/product/${variant.product.handle}`}
                          onClick={toggleCart}
                        >
                          {title}
                        </a>
                      </Link>
                      {variant.title !== 'Default Title' && (
                        <p className="m-0 text-sm">{variant.title}</p>
                      )}
                      <p className="my-1 mx-0">Qty: {quantity}</p>
                    </div>

                    <p className="m-0">£{variant.price}</p>
                  </div>

                  <button
                    className="appearance-none"
                    type="button"
                    onClick={handleRemoveFromCart}
                  >
                    <BiX />
                  </button>
                </div>
              );
            })}
          </div>

          <div
            className={`flex flex-col mt-10 w-full ${
              !side && 'md:grid md:grid-cols-2 gap-5'
            }`}
          >
            <div className="flex flex-col mt-5 flex-1">
              <div className="text-sm font-bold uppercase mb-2">
                Gift order message or instructions
              </div>

              <textarea className="w-full bg-white border-2 border-gray-300 text-dark p-2 rounded-md" />
            </div>
            <div className="flex flex-col items-end justify-end mt-5 flex-1">
              <p className="text-2xl m-0 font-bold">
                £{checkout.subtotalPrice}
              </p>

              {side && (
                <Link href="/cart" passHref>
                  <SecondaryButton handleClick={toggleCart}>
                    View Cart
                  </SecondaryButton>
                </Link>
              )}

              <div className="w-full mt-3">
                <Link href={checkout.webUrl}>
                  <PrimaryButton>Checkout</PrimaryButton>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}

      {!hasItems && !checkoutLoading && <p>Your cart is empty</p>}
    </div>
  );
};

type CartContentProps = {
  side?: boolean;
  toggleCart?: () => void;
};

export default CartContent;
